<template>
  <div class="titleBar f_flex f_a_c f_j_c">
    <div class="left">
      <slot name='leading'>
        <div class="cancle" @click="cancleFn">取消</div>
      </slot>
    </div>
    <div class="middle">
      <slot>
        <div class="title">{{title}}</div>
      </slot>
    </div>
    <div class="right">
      <slot name='action'></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    cancleFn() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.titleBar {
  position: relative;
  height: 60px;
  background: #fff;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  padding: 0 15px;
  .left {
    position: absolute;
    left: 15px;
  }
  .right {
    position: absolute;
    right: 15px;
  }
  .cancle {
    font-size: 15px;
    color: #4A4A4A;
  }
  .title {
    font-size: 16px;
    color: #2D2D2D;
  }
}
</style>