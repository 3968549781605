<template>
  <div class="f_flex f_a_c f_w">
    <div class="imgItem mg7" v-for='(item, index) in imgList' :key='index'>
      <img style="width: 100%; height: 100%" v-if='item && item.base' :src="item.base" alt="">
      <div class="iconClose f_flex f_a_c f_j_c" v-if='item && item.base' @click="removeItemFn(index, item.name)">
        <!-- <i class="iconfont icon-close2"></i> -->
        <img src="../../assets/close_icon.png" style="width: 15px; height: 15px" alt="">
      </div>
    </div>

    <div class="_c-photo mg7" v-if='imgList.length<9'>
      <div style="width: 100%; height: 100%" class="f_flex f_a_c f_j_c">
        <i class="iconfont icon-add"></i>
        <p v-if='title' style="font-size: 14px; color: #444; margin-top: 15px">{{title}}</p>
      </div>
      <canvas ref='canvasImg' id='canvasImg'></canvas>
      <!-- capture="camera" -->
      <input type="file" v-if='showFile' size="9" multiple ref='file' class="file_input" accept="image/jpeg,image/gif,image/jpg,image/png" @change='uploadImg' />
    </div>
  </div>
</template>

<script>
import postApi from '@/api/post'
import Compressor from 'compressorjs';

export default {
  name: 'photo',
  data: ()=> ({
    file: '',
    maxLimit: 400, // 压缩临界 400k, 小于400k不压缩, 控制压缩大小可以在 imgChangeBlob 方法中控制
    imgList: [],
    emitList: [],
    showFile: true,
  }),
  props: {
    title: String
  },
  methods: {
    uploadImg(e) {
      const files = e.target.files
      // const arr = []
      const self = this
      if(files.length + this.imgList.length > 9) {
        this.$toast('最多上传9张图片')
        return
      }
      // for(let i=0; i<files.length; i++) {
      //   arr.push(this.filePromiiseItem(files[i]))
      // }
      // console.log('length:', files.length);
      if (!files.length) {
        return;
      }

      this.$load.show('加载中')
      const imgLength = this.imgList.length
      const fileList = []
      let j=0
      for(let i=0; i<files.length; i++) {
        new Compressor(files[i], {
          quality: 0.75,
          success(result) {
            const fileItem = new File([result], files[i].name)
            // fileList.push(fileItem)
            fileList[i] = fileItem
            var reader = new window.FileReader();
            reader.readAsDataURL(result);
            reader.onloadend = function() {
              const base64data = reader.result;
              j++
              // self.imgList[i] = base64data
              self.$set(self.imgList, i+imgLength, {
                base: base64data,
                name: files[i].name
              })
              if (j === files.length) {
                self.uploadFn(fileList)
              }
            }
          },
          error(err) {
            this.$load.hide()
            this.$toast(`图片上传出错了-${err.message}`)
          },
        })
      }
      
      // Promise.all(arr).then(res => {
      //   const fileList = []
      //   for(let i=0; i<res.length; i++) {
      //     new Compressor(res[i].file, {
      //       quality: 0.76,
      //       success(result) {
      //         const fileItem = new File([result], res[i].file.name)
      //         fileList.push(fileItem)
      //         if (fileList.length == res.length) {
      //           // console.log('上传好了', fileList);
      //           self.uploadFn(fileList)
      //         }
      //         var reader = new window.FileReader();
      //         reader.readAsDataURL(result);
      //         reader.onloadend = function() {
      //           const base64data = reader.result;
      //           self.imgList.push(base64data)
      //         }
      //       },
      //       error(err) {
      //         this.$load.hide()
      //         this.$toast(`图片上传出错了-${err.message}`)
      //       },
      //     })
      //   }
      // })
    },
    uploadFn(file) {
      postApi.imgUpload({
        uploadFiles: file,
        bizType: 1
      }).then(res => {
        if (res.data) {
          this.emitList.push(...res.data.uriList)
          this.eventEmit()
          
          this.showFile = false
          setTimeout(()=> {
            this.showFile = true
            this.$load.hide()
          }, 50)
        }
      })
    },
    // 单张图片处理 转base64
    filePromiiseItem(file) {
      const limitSize = 50 * 1024; // 最大图片限制 50M
      return new Promise((resolve) => {
        const reader = new FileReader()
        const minLimitSize = this.maxLimit;
        reader.onload = ev => {
          const data = ev.target.result
          const size = (data.length / 1024);
          if (size > limitSize) {
            resolve()
          } else {
            if (size <= minLimitSize) {
              resolve({file: file, data})
            } else {
              // this.imgChangeBlob(data, file.name).then(e => {
              //   resolve({file: e.file, data: e.data})
              // })
              resolve({file: file, data})
            }
          }
        }
        reader.readAsDataURL(file)
      })
    },

    // 图片压缩base64 => canvas => blob
    imgChangeBlob(url, name) {
      const canvas = this.$refs.canvasImg || document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();
      img.src = url

      return new Promise((resolve) => {
        img.onload = function () {
          // 图片原始尺寸
          var originWidth = this.width;
          var originHeight = this.height;
          // 最大尺寸限制 这个就是上传的最终大小
          var maxWidth = this.width, maxHeight = this.height;
          // 目标尺寸
          var targetWidth = originWidth, targetHeight = originHeight;
          if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
              // 更宽，按照宽度限定尺寸
              targetWidth = maxWidth;
              targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
              targetHeight = maxHeight;
              targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
          }
          
          // canvas对图片进行缩放
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          // 清除画布
          context.clearRect(0, 0, targetWidth, targetHeight);
          // 图片压缩
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          // canvas转为blob并上传
          canvas.toBlob(function (blob) {
            const data = canvas.toDataURL('image/png', 0.8)
            const f = new File([blob], name)
            resolve({blob, data, file: f})
          }, 'image/png');
        };
      })
    },
    removeItemFn(index, name) {
      this.imgList.splice(index, 1)
      // this.emitList.splice(index, 1)
      const arr = this.emitList.filter(item => item.originName == name)
      const difArr = this.emitList.filter(item => item.originName != name)
      arr.pop()
      this.$set(this, 'emitList', [...arr, ...difArr])

      this.eventEmit()
    },
    eventEmit() {
      const arr = this.setSameIndexEmitImg(this.emitList)
      this.$emit('change', {uriList: arr})
    },
    setSameIndexEmitImg(emitList) {
      const imgList = this.imgList
      const arr = emitList.reduce((pre, next) => {
        const name = next.originName
        let i = 0;
        imgList.find((item, index) => {
          if (item.name == name && !pre[index]) {
            i = index
          }
        })
        pre[i] = next
        return pre
      }, [])
      return arr
    },
    init() {
      this.imgList = []
      this.emitList = []
      this.$refs.file.value = ''
    }
  }
}
</script>

<style lang='less'>
._c-photo {
  position: relative;
  border: 1px dashed #ccc;
  width: 108px;
  height: 108px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 9px;
  .file_input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
  .content_img {
    position: absolute;
    width: 100%;
  }
  .icon-add {
    font-size: 24px;
  }
}
.imgItem {
  width: 108px;
  height: 108px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  margin-bottom: 9px;
  .iconClose {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    color: #666;
  }
  .icon-close2 {
    font-size: 18px;
  }
}
.mg7 {
  margin-right: 7px;
}
.mg0 {
  margin-right: 0;
}
</style>
