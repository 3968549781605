<template>
  <page :styleWrap='{background: "#fff"}'>
    <div>
      <title-bar style="position: sticky; top: 0" :title='title'>
        <div slot='action'>
          <button class="theme_bg postBtn theme_bg" @click='postFn'>发布</button>
        </div>
      </title-bar>
      <div style="padding: 0 15px">

        <cell-item title='发帖类型' showArrow @click.native='showPostTypeFn'>
          <img slot='img' class="icon_set" src="../../assets/post_category.png" alt="">
          <div slot='right'>
            {{ postsType == 0 ? '普通贴' : '打卡贴' }}
          </div>
        </cell-item>
        <cell-item :title='keyword' showArrow @click.native='goStyleNoFn'>
          <img slot='img' class="icon_set" src="../../assets/post_label.png" alt="">
          <div slot='right'>
            {{ get_styleNum ? get_styleNum.styleNo : '' }}
          </div>
        </cell-item>

        <cell-item title='标签' showArrow @click.native='goLabelManagerFn'>
          <img slot='img' class="icon_set" src="../../assets/post_jin.png" alt="">
          <div slot='right' class="f_flex f_a_c f_j_e f_w cellRight text_line1">
            <div class="labelItem" v-for='(item, index) in get_labelList' :key='index'>#{{ item?item.labelName:'' }}#</div>
          </div>
        </cell-item>

        <textarea v-model="content" placeholder="输入要发布的内容" class="textArea"></textarea>


        <div class="vif" v-if="isNotUpdate()"> 

              <div class="f_flex f_w">
                <photo ref='upload' @change='changeFn'></photo>

              </div>

              <div class="f_flex f_w">
                
              </div>

            

              <cell-item title='提醒谁看' v-if='$route.query.postsSource ==1 && postsType == 0' showArrow @click.native='goRemindListFn'>
                <img slot='img' class="icon_set" src="../../assets/post_at.png" alt="">
                <div v-if='get_anchorType == 2' slot='right' class="f_flex f_a_c f_j_e f_w theme_c cellRight"
                    style="box-sizing: border-box">
                  <span style="margin-left: 5px" v-for='(item, index) in get_anchorList'
                        :key='index'>@{{ item.userName }}</span>
                </div>

                <div v-if='get_anchorType == 3' slot='right' class="f_flex f_a_c f_j_e f_w theme_c cellRight"
                    style="box-sizing: border-box">
                  <span>@所有人</span>
                </div>
              </cell-item>

              <cell-item title='所在位置' class="f_a_s" @click.native='goMap' showArrow>
                <img slot='img' class="icon_set" src="../../assets/post_location.png" alt="">
                <div slot='right' class='addressPanel'>
                  {{ get_geo ? get_geo.address : '' }}
                </div>
              </cell-item>

              <fileUpload v-if='postsType == 0' ref='uploadFile' @change='uploadFilesChange'></fileUpload>

              <cell-item title='需跟近' class="f_a_s" v-if='postsType == 0'>
                <img slot='img' class="icon_set" src="../../assets/follow_up.png" alt="">
                <div slot='right' class='followUpPanel'>
                  <my-switch ref='trackStatusSwitch' v-model="trackStatus"  font-size="24px"/>
                </div>
              </cell-item>

            </div>
            <div class="f_flex f_j_e" @click='isUrgent=!isUrgent' v-if='$route.query.postsSource == 1'>
              <button class="btnCheck" :class="{btnUrgent: isUrgent}">
                <i class="iconfont icon-check1"></i>
                紧急
              </button>
            </div>

        </div>

        <van-action-sheet
          v-model="showPostTypePop"
          :actions="postTypeCctions"
          cancel-text="取消"
          close-on-click-action
          @cancel="postTypeCancelFn"
          @select="onPostTypeSelect"
        />
        
      <div style="height: 80px"></div>
    </div>
  </page>
</template>

<script>
import titleBar from '@/components/titleBar/index.vue'
import cellItem from '@/components/cellItem/index.vue'
import photo from '@/components/upload/index.vue'
import fileUpload from '@/components/upload/uploadFile.vue'
import postApi from '@/api/post'
import {mapGetters, mapActions} from 'vuex'
import mySwitch from '@/components/mySwitch/index.vue'



export default {
  components: {
    titleBar,
    cellItem,
    photo,
    fileUpload,
    // Uploader
    mySwitch,
  
  },
  data () {
    return {
      title: '',
      imgTosubmitList: [],
      content: '',
      isUrgent: false, // 是否紧急
      fileList: [],
      postsSource: 0,
      files: [],
      keyword: '款号',
      trackStatus: false,
      showPostTypePop: false,
      postTypeCctions: [{name: '普通贴', postsType: 0}, {name: '打卡贴', postsType: 1}],
      postsType: 0, // 0-普通贴 1-打卡贴
    }
  },
  computed: {
    ...mapGetters ([
      'get_styleNum',
      'get_labelList',
      'get_geo',
      'get_anchorList',
      'get_anchorType',
      'get_router',
      'get_updateContent'
    ]),
  },
  methods: {
    ...mapActions ([
      'set_postStatus',
      'set_styleNum',
      'set_labelList',
      'set_anchorList',
      'set_geo',
      'set_anchorType'
    ]),
    changeFn (e) {
      const {uriList} = e
      this.$load.hide ()
      this.$set (this, 'imgTosubmitList', uriList)
    },
    uploadFilesChange (e) {
      const {uriList} = e
      this.$load.hide ()
      this.$set (this, 'fileList', uriList)
    },
    showPostTypeFn() {
      this.showPostTypePop = true
    },
    postTypeCancelFn() {
      this.showPostTypePop = false
    },
    onPostTypeSelect(item) {
      this.postsType = item.postsType
    },
    goStyleNoFn () {
      const {postsSource} = this.$route.query
      if (postsSource && postsSource > 1) {
        this.$router.push ({
          path: '/post/styNum',
          query: this.$route.query
        })
      } else {
        this.$router.push ({
          path: '/build/styleNum',
          query: this.$route.query
        })
      }
    },
    goLabelManagerFn () {
      const {postsSource} = this.$route.query
      console.log("this.$route.query", this.$route.query)
      if (postsSource && postsSource > 1) {
        this.$router.push ({
          path: '/post/createLabel',
          query: this.$route.query
        })
      } else {
        this.$router.push ({
          path: '/post/label',
          query: this.$route.query
        })
      }
    },

    postFn () {
      const {groupId, companyId, postsSource} = this.$route.query
      console.log("this.get_labelList", this.get_labelList)
      if (postsSource == 1) {
        if (!(this.get_styleNum && this.content && this.get_labelList)) {
          this.$toast ('款号，标签，内容不能为空')
          return;
        }
      }

      if (this.content) {
        const labelIdList = []
        this.get_labelList.forEach (item => {
          labelIdList.push (item.labelId)
        })
        const remindUserIdList = []
        this.get_anchorList.forEach (item => {
          remindUserIdList.push (item.userId)
        })
        let imgList = []
        if (this.imgTosubmitList.length > 9) {
          imgList = this.imgTosubmitList.filter ((_, index) => index < 9)
        } else {
          imgList = this.imgTosubmitList
        }
        const uploadImgList = []
        imgList.forEach (item => {
          uploadImgList.push (item.uri)
        })

        let uploadFileList = []
        if (this.fileList.length > 5) {
          uploadFileList = this.fileList.filter((_, index) => index < 9)
        } else {
          uploadFileList = this.fileList
        }
        const obj = {
          postsSource: this.$route.query.postsSource,
          content: this.content,
          remindUserType: this.get_anchorType || 1,
          remindUserIdList: (this.get_anchorType && this.get_anchorType == 3) ? [] : remindUserIdList,
          postsImgUrlList: uploadImgList,
          attachedUrlList: uploadFileList,
          urgentStatus: this.isUrgent ? 2 : 1,
          trackStatus: this.trackStatus ? 1 : 0,
          postType: this.postsType
        }
        if (this.get_geo) {
          obj.longitude = this.get_geo.position.lng
          obj.latitude = this.get_geo.position.lat
          obj.locationAddress = this.get_geo.address
        }

        obj.styleNoId = this.get_styleNum?.styleId || this.get_styleNum?.id || this.get_styleNum?.bizId
        obj.labelIdList = labelIdList

        const companyInfo = this.$global.getItem ('companyInfo', true)
        if (companyInfo || companyId) {
          obj.companyId = companyId || companyInfo.companyId
        }
        if (groupId) {
          obj.groupId = groupId
        }
        // console.log (obj);
        if (this.$route.query.postsId) {

          postApi.postDetail({
                postsId: this.$route.query.postsId,
            }).then( detailRes => {
                let updateObj = detailRes.data.postsInfo
                updateObj.styleNoId = this.get_styleNum?.styleId || this.get_styleNum?.id || this.get_styleNum?.bizId
                updateObj.labelIdList = labelIdList
                updateObj.content = this.content

                if (updateObj.beRemindUserList && updateObj.beRemindUserList.length > 0) {
                  let l = []
                  updateObj.beRemindUserList.forEach(function(item) {
                      l.push(item.userId)
                  })
                  updateObj.remindUserIdList = l
                }

                
                
                
                postApi.postUpdate (updateObj).then (res => {
                  if (res.data) {
                    this.$toast (res.data.msg)
                    this.set_postStatus ('success')
                    setTimeout (() => {
                      this.$router.back ()
                      //发布成功，清空数据
                      this.initData (false, 'postSuccess')
                    }, 200)
                  }
                })
            })

          
          
        } else {
            postApi.postPublish (obj).then (res => {
            if (res.data) {
              this.$toast (res.data.msg)
              this.set_postStatus ('success')
              setTimeout (() => {
                this.$router.back ()
                //发布成功，清空数据
                this.initData (false, 'postSuccess')
              }, 200)
            }
          })
        }

        
      } else {
        this.$toast ('内容不能为空')
      }
    },
    goMap () {
      let geo = window.sessionStorage.getItem ('geoLocation')
      let url = '/map'
      if (geo && JSON.parse (geo)) {
        geo = JSON.parse (geo)
        const geoInfo = this.get_geo
        if (geoInfo && geoInfo.position) {
          geo = [geoInfo.position.lng, geoInfo.position.lat]
        }
        url = `${url}?lng=${geo[0]}&lat=${geo[1]}&flag=true`
      }
      this.$router.push (url)
    },
    init () {
      const {postsSource, groupName, keyword} = this.$route.query
      const name = ['', '工作群', '广场', '私密本']
      if (keyword) {
        this.keyword = keyword
      }
      if (groupName) {
        let gname = groupName.length > 10 ? `${groupName.substr (0, 10)}...` : groupName
        this.title = `【群】${gname}`
      } else {
        this.title = name[postsSource]
      }
      this.trackStatus = false
      if (this.$refs.trackStatusSwitch) {
        this.$refs.trackStatusSwitch.init()
      }
      
    },
    goRemindListFn () {
      this.$router.push ({
        path: '/post/choose',
        query: {
          ...this.$route.query,
          from: 'create'
        }
      })
    },
    initData (isCopy, fromPage) {
      console.log('isCopy', isCopy)
      console.log('fromPage', fromPage)
      if (!isCopy && fromPage != 'update') {
        console.log('set label style empty')
        this.set_styleNum (null)
        this.set_labelList ([])
        this.postsType = 0

        this.set_anchorList ([])
        this.set_postStatus (null)
        this.set_anchorType (1)
        this.set_geo (null)
      }
     
      
      if (this.$refs.upload) {
        this.$refs.upload.init ()
      }
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.init ()
      }
      
      
      if (fromPage != 'update') {
        this.content = ''
      } else {
        this.content = this.get_updateContent
      }
      
      this.fileList = []
      this.imgTosubmitList = []
      const {postsSource} = this.$route.query
      this.postsSource = postsSource
      this.isUrgent = false
      
    },

    
    isNotUpdate() {
      return this.$route.query.from != 'update'
    }
    // updatetValue (e) {
    //   // console.log (e);
    // }
  },
  mounted () {
    this.init ()
    console.log("post mounted init", this.get_updateContent)
    
    let fromPage = this.$route.query.from
    
    if (fromPage != 'copy' && this.get_router != 'backward') {
         this.initData (true, fromPage)
    }
    if (fromPage == 'copy') {
      this.initData (true, fromPage)
    }
    this.$route.meta.change = () => {

      let fromPage = this.$route.query.from
      console.log('post router change', this.get_router)
      console.log("fromPage", fromPage)

      // if (fromPage != 'copy' && this.get_router != 'backward') {
      //    this.initData ()
      // }
      if (fromPage == 'copy' && this.get_router == 'forward') {
         this.initData (true, fromPage)
        this.init ()
      } else {
            if (this.get_router != 'backward' ) {
              this.initData (false, fromPage)
              this.init ()
              console.log('this.trackStatus2', this.trackStatus)
            }
      }
     
      
    }
  }
}
</script>

<style lang="less" scoped>
.icon_set {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.postBtn {
  font-size: 13px;
  padding: 3px 12px;
  border-radius: 30px;
  border: none;
  outline: none;
}

.textArea {
  border: none;
  outline: none;
  height: 120px;
  resize: none;
  margin-top: 11px;
  width: 100%;
}

.btnCheck {
  font-size: 12px;
  border-radius: 40px;
  padding: 3px 6px;
  border: none;
  outline: none;
  background: #D8D8D8;
  color: #fff;
  margin-top: 13px;
  margin-right: 12px;
}

.btnUrgent {
  background: #d43f33 !important;
}

.cellRight {
  width: 230px;
}

.labelItem {
  margin-left: 5px;
  font-size: 13px;
}

.addressPanel {
  font-size: 12px;
  color: #999;
  padding-left: 6px;
}

.followUpPanel > div {
  
  color: #999;
  padding-left: 6px;
}

</style>
