<template>

<div class="underline">

  <div class="f_flex f_a_c f_j_sb personItem"  @click="onClick">
      <div class="title f_flex f_a_c f_s0">
        <img slot='img' class="icon_set" src="../../assets/attach_upload.png" alt="">
        添加附件
        <input type="file" size="5" multiple ref="fileInputRef" accept="" style="display: none;" @change='uploadFile'>
      </div>
      <div class="f_flex f_a_c">
        <i class="iconfont icon-ARROW"></i>
      </div>

  </div>

      <!-- 文件列表 start-->
  <div class="upload-list">
    <div class="f_flex f_a_c f_j_sb item"  v-for='(item, index) in fileList' :key='index'>
      <span class="title">
         {{item.name}} 
      </span>
          
      <div class="iconClose " title="删除文件" v-if='item && item.base' @click="removeItemFn(index, item.name)" >
              
              <img src="../../assets/file_upload_close.png" style="width: 13px; height: 13px" alt="">
          </div>
    </div>
  </div>
        <!-- 文件列表 end--> 
</div>
</template>

<script>
import postApi from '@/api/post'


export default {
  name: 'uploadFile',
  data: ()=> ({
    file: '',
    maxLimit: 400, // 压缩临界 400k, 小于400k不压缩, 控制压缩大小可以在 imgChangeBlob 方法中控制
    fileList: [],
    emitList: [],
    showFile: true,
  }),
  props: {
    title: String
  },
  methods: {
    onClick() {
      const el = this.$refs.fileInputRef;
      if (!el) {
        return;
      }
      el.click();
    },
    uploadFile(e) {
      const files = e.target.files
      // const arr = []
      const self = this
      if(files.length + this.fileList.length > 5) {
        this.$toast('最多上传5个文件')
        return
      }
      // for(let i=0; i<files.length; i++) {
      //   arr.push(this.filePromiiseItem(files[i]))
      // }
      // console.log('length:', files.length);
      if (!files.length) {
        return;
      }

      this.$load.show('加载中')
      const imgLength = this.fileList.length
      const uploadFileList = []
      let j=0
      for(let i=0; i<files.length; i++) {
        uploadFileList[i] = files[i]
        j++
        // self.fileList[i] = base64data
        self.$set(self.fileList, i+imgLength, {
            base: files[i],
            name: files[i].name
        })
        console.log('已经上传文件：', self.fileList)
        if (j === files.length) {
            
            self.uploadFn(uploadFileList)
        }

        // new Compressor(files[i], {
        //   quality: 0.75,
        //   success(result) {
        //     const fileItem = new File([result], files[i].name)
        //     // fileList.push(fileItem)
        //     fileList[i] = fileItem
        //     var reader = new window.FileReader();
        //     reader.readAsDataURL(result);
        //     reader.onloadend = function() {
        //       const base64data = reader.result;
              
        //     }
        //   },
        //   error(err) {
        //     this.$load.hide()
        //     this.$toast(`文件上传出错了-${err.message}`)
        //   },
        // })
      }
      
      // Promise.all(arr).then(res => {
      //   const fileList = []
      //   for(let i=0; i<res.length; i++) {
      //     new Compressor(res[i].file, {
      //       quality: 0.76,
      //       success(result) {
      //         const fileItem = new File([result], res[i].file.name)
      //         fileList.push(fileItem)
      //         if (fileList.length == res.length) {
      //           // console.log('上传好了', fileList);
      //           self.uploadFn(fileList)
      //         }
      //         var reader = new window.FileReader();
      //         reader.readAsDataURL(result);
      //         reader.onloadend = function() {
      //           const base64data = reader.result;
      //           self.fileList.push(base64data)
      //         }
      //       },
      //       error(err) {
      //         this.$load.hide()
      //         this.$toast(`图片上传出错了-${err.message}`)
      //       },
      //     })
      //   }
      // })
    },
    uploadFn(file) {
      console.log("fileUpload", file)
      postApi.fileUpload({
        uploadFiles: file,
        uploadType: 2
      }).then(res => {
        if (res.data) {
          this.emitList.push(...res.data.uriList)
          this.eventEmit()
          
          this.showFile = false
          setTimeout(()=> {
            this.showFile = true
            this.$load.hide()
          }, 50)
        }
      })
    },

    // 图片压缩base64 => canvas => blob
    imgChangeBlob(url, name) {
      const canvas = this.$refs.canvasImg || document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();
      img.src = url

      return new Promise((resolve) => {
        img.onload = function () {
          // 图片原始尺寸
          var originWidth = this.width;
          var originHeight = this.height;
          // 最大尺寸限制 这个就是上传的最终大小
          var maxWidth = this.width, maxHeight = this.height;
          // 目标尺寸
          var targetWidth = originWidth, targetHeight = originHeight;
          if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
              // 更宽，按照宽度限定尺寸
              targetWidth = maxWidth;
              targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
              targetHeight = maxHeight;
              targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
          }
          
          // canvas对图片进行缩放
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          // 清除画布
          context.clearRect(0, 0, targetWidth, targetHeight);
          // 图片压缩
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          // canvas转为blob并上传
          canvas.toBlob(function (blob) {
            const data = canvas.toDataURL('image/png', 0.8)
            const f = new File([blob], name)
            resolve({blob, data, file: f})
          }, 'image/png');
        };
      })
    },
    removeItemFn(index, name) {
      this.fileList.splice(index, 1)
      // this.emitList.splice(index, 1)
      const arr = this.emitList.filter(item => item.originName == name)
      const difArr = this.emitList.filter(item => item.originName != name)
      arr.pop()
      this.$set(this, 'emitList', [...arr, ...difArr])

      this.eventEmit()
    },
    eventEmit() {
      const arr = this.setSameIndexEmitImg(this.emitList)
      this.$emit('change', {uriList: arr})
    },
    setSameIndexEmitImg(emitList) {
      const fileList = this.fileList
      const arr = emitList.reduce((pre, next) => {
        const name = next.originName
        let i = 0;
        fileList.find((item, index) => {
          if (item.name == name && !pre[index]) {
            i = index
          }
        })
        pre[i] = next
        return pre
      }, [])
      return arr
    },
    init() {
      this.fileList = []
      this.emitList = []
      this.$refs.fileInputRef.value = ''
    }
  }
}
</script>

<style lang='less'>
.mg7 {
  margin-right: 7px;
}
.mg0 {
  margin-right: 0;
}


.icon_set {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.upload-list {
  padding: 0 0 5px 45px;
  .title {
    font-size: 13px;
    font-weight: 400;
    color: #4A90E2;
  }
  .item {
    height: 30px;
  }
  .iconClose {
    padding: 0 40px 0 0;
  }
}

.personItem {
  padding: 14px 0;
  &:active {
    background: rgba(180, 170, 170, 0.06);
  }
  .title {
    font-size: 15px;
    color: #2D2D2D;
  }
  .val {
    font-size: 15px;
    color: #9B9B9B;
  }
}
.icon-ARROW {
  font-size: 20px;
  margin-left: 8px;
  color: #C4C4C4;
}
</style>
